

















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IStrDetailValue } from "@/Services/Interfaces";

import { globalUpdateDetailService } from "@/Services/GlobalService";

@Component
export default class ConfirmActionButton extends Vue {
  public jetzdoAction = false;
  thismodel: IStrDetailValue = <IStrDetailValue>{};
  funct: string = "";
  thiscanaction = true;
  constructor() {
    super();

  }

  @Prop()
  actionLabel: string | undefined ;

  @Prop()
  model: IStrDetailValue | undefined ;

  @Prop()
  canaction: boolean|undefined ;

  @Prop()
  functionName : string|undefined;

  cancel(): void {
    this.jetzdoAction = false;
  }

  data() {
    return {
      thismodel: this.model,
      thiscanaction: this.canaction,
      funct: this.functionName,
      actionLabel: this.actionLabel
    };
  }


  public get doAction(): boolean {
    return this.jetzdoAction;
  }

  public set doAction(v: boolean) {
    this.jetzdoAction = v;
  }

  actionitem(): void {

    if (this.doAction && this.doAction === true) {

      if (this.model != undefined) {  
        this.thismodel = this.model;
        this.$emit('doAction', {...this.thismodel});
      }
    }

    this.jetzdoAction = !this.jetzdoAction;
  }

  created() {}
}
