




































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  IStrDetailValue,
  IInfo,
  IUrllink,
  IBaseResult,
  IBaseObjectResult,
  IEncrypt,
  ICryptData,
  IMedia,
  IMediaConvert,
  IMediaDynamic,
  ISrchValue,
  IStrValue,
  IStefsLinkModel,
  IWhatsup,
} from "@/Services/Interfaces";
import FileUpload from "vue-upload-component";

import { InfoType, CrudType, SearchType } from "@/Services/enums";
import {
  globalUpdateDetailService,
  globalInfoActionService,
  globalInfoService,
  globalUpdateMediaService,
} from "@/Services/GlobalService";
import router from "../../router";
import store from "@/store";
import DeleteButton from "../Controls/DeleteButton.vue";
import { dataService } from "@/Services/DataServices";
import ConfirmActionButton from "../Controls/ConfirmActionButton.vue";

@Component({ components: { FileUpload, DeleteButton, ConfirmActionButton } })
export default class ActionComponent extends Vue {
  token: string;

  valuedecryptcode: string;
  encryptcode: IEncrypt;

  name: string;
  urllink: IUrllink = <any>{};
  info: IInfo;

  @Prop()
  model: IStrDetailValue | undefined;

  @Prop()
  showAction: boolean | undefined;

  @Prop()
  medias: IMedia[] | undefined;

  thismodel: IStrDetailValue;
  showthisAction: boolean;

  constructor() {
    super();
    this.name = "ActionComponent";    
    this.info = <IInfo>{};
    this.encryptcode = <IEncrypt>{};
    this.thismodel = <IStrDetailValue>{};
    this.showthisAction = false;
    this.token = "";
    this.valuedecryptcode = "";
  }

  linkadd: IMediaDynamic | undefined;

  getPicture(item: IMedia): string {
    try {
      return (
        item.link +
        (this.linkadd != undefined &&
        this.linkadd.id == item.id &&
        this.linkadd.query != undefined
          ? this.linkadd.query
          : "")
      );
    } catch (e) {
      return item.link;
    }
  }

  convert(media: IMedia, typ: string): void {
    var p = <IMediaConvert>{
      typ: typ,
      media: media,
      base_id: this.thismodel.base_id,
    };
    dataService.InitbyToken(this.token);
    dataService
      .post<IMediaConvert, IMediaConvert>("Service/mediaConvert", p)
      .then((d: IMediaConvert) => {
        media = d.media;
        globalUpdateMediaService.sendMessage(p);
        var add = d.media as IMediaDynamic;
        add.query = "?" + new Date().getMilliseconds().toString();
        this.linkadd = add;
      })
      .catch((ex: any) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: media.name + " " + typ,
          type: InfoType.error,
          error: ex,
        });
      });
  }

  doLinkAction(): void {
  
    dataService.InitbyToken(store.getters.user.token);
    var url = "Service/getSteflink/" + this.thismodel.base_id;
    dataService.get<IStefsLinkModel>(url).then(
      (g) => {
  
        var wh = <IWhatsup>{};
        wh.Id = -1;
        wh.To = g.Email;
        wh.From = "stefhorst@hotmail.com";
        wh.Text =
          "Hi, Hier gehts zur Webseite <a href='" +
          window.location.origin +
          "/#/login/?token=" +
          g.Token +
          "'>Link zu Stefs Webseite </> ";
        wh.Titel = "Hier gehts zu Stefs Seite mit Anmeldung";
        dataService
          .post<IWhatsup, IBaseResult>("Service/sendmail", <IWhatsup>wh)
          .then((d) => {
            globalInfoService.sendMessage(<IInfo>{
              msg: "Versand an " + g.Email,
              error: d.error,
              type: InfoType.finished,
            });
          })
          .catch((ex) => {
            globalInfoService.sendMessage(<IInfo>{
              msg: "Link Senden ging wohl nicht... schade",
              error: ex,
              type: InfoType.error,
            });
          });
      },
      (e) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Fehler beim  Senden eines  Stef Links",
          error: e,
        });
      }
    );
  }
  uploadaction(): string {
    return dataService.baseurl + "service/upload";
  }

  // https://stackoverflow.com/questions/19094547/aes-encryption-in-c-sharp-and-decryption-in-cryptojs
  decrypt(): void {
    if (!this.encryptcode) return;

    var encryptedtext = dataService.Encrypt(
      this.encryptcode.key,
      this.valuedecryptcode
    );

    var url = "Service/GetSecureValue";
    dataService
      .post<ICryptData, IBaseObjectResult>(url, <ICryptData>{
        id: this.thismodel.id,
        encryptedtext: encryptedtext,
      })
      .then((result) => {
        if (result.error) {
          globalInfoService.sendMessage(<IInfo>{
            msg: result.message,
            error: result.error,
          });
        } else {
          this.thismodel.strvalue = result.result;
        }
      })
      .catch((e) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Wert konnte nicht ermittelt werden ?",
          error: e,
        });
      })
      .catch((e) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Da ging aber etwas schief...Code falsch ?",
          error: e,
        });
      });
  }

  data() {
    return {
      thismodel: this.model,
      linkadd: undefined,
      files: [],
      msg: "Action",
      valuedecryptcode: "",
      token: "",
      loeschen: "",
      showthisAction: false
    };
  }

  IsSecureText(): boolean {
    if (this.thismodel.valuetyp == undefined) return false;
    return this.thismodel.valuetyp.toLowerCase() == "secure";
  }

  extensions(): string {
    if (this.thismodel.valuetyp.toLowerCase() == "picture") {
      return "gif,jpg,jpeg,png";
    } else if (this.thismodel.valuetyp.toLowerCase() == "movie") {
      return "mp4,avi";
    } else if (this.thismodel.valuetyp.toLowerCase() == "audio") {
      return "mp3";
    } else {
      return "pdf,docx";
    }
  }

  acceptable(): string {
    if (this.thismodel.valuetyp.toLowerCase() == "picture") {
      return "image/*";
    } else if (this.thismodel.valuetyp.toLowerCase() == "movie") {
      return "video/*";
    } else if (this.thismodel.valuetyp.toLowerCase() == "audio") {
      return "audio/*";
    } else {
      return "application/*";
    }
  }

  canSendLink(): boolean {
    if (
      store == undefined ||
      store.getters.user == undefined ||
      store.getters.user.email == undefined
    )
      return false;
    if (this.thismodel.typname == undefined) return false;
    if (this.thismodel.typname.toLowerCase() != "adresse") return false;
    var result =
      store.getters.user.email.toLowerCase() == "stefhorst@hotmail.com";    
    return result;
  }

  canGroesser(): boolean {
    return (
      this.thismodel != undefined &&
      this.thismodel.inputtype != undefined &&
      this.thismodel.inputtype.toLocaleLowerCase() == "textarea"
    );
  }

  groesser(): void {
    this.$emit("labelsize", { rows: 2, cols: 0 });
  }

  inputFilter(newFile: any, oldFile: any, prevent: any): any {
    if (newFile && oldFile && !newFile.active && oldFile.active) {
      // 获得相应数据
      console.log("response", newFile.response);
      if (newFile.xhr) {
        //  获得响应状态码
        console.log("status", newFile.xhr.status);
      }
    }

    if (newFile && !oldFile) {
      // Before adding a file
      // 添加文件前
      // Filter system files or hide files
      // 过滤系统文件 和隐藏文件
      if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
        return prevent();
      }
      // Filter php html js file
      // 过滤 php html js 文件
      if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
        return prevent();
      }
    }
  }

  inputFile(newFile: any, oldFile: any): any {
    if (newFile && !oldFile) {
      // add
    }
    if (newFile && oldFile) {
      // Upload progress
      if (newFile.progress !== oldFile.progress) {
        console.log("progress", newFile.progress, newFile);
      }

      // Upload error
      if (newFile.error !== oldFile.error) {
        newFile.error = "Error";
        console.log("error", newFile.error, newFile);
      }

      // Uploaded successfully
      if (newFile.success !== oldFile.success) {
        if (newFile.progress >= 100) {
          newFile.error = "delivered";
        }
        console.log("success", newFile.success, newFile);
      }
    }
    if (!newFile && oldFile) {
      // remove
    }
  }

  public gotoUrllink() {
    if (this.urllink) {
      if (this.urllink.typ == 'diashow') 
      {
        router.push('/gallery/'+ this.thismodel.base_id );
      } else 
      {
        window.open(this.urllink.href, this.urllink.self ? "_self" : "_blank", 'noreferrer');
      }
    }
  }
  public canDelete(): boolean {
    return this.thismodel.crud == CrudType.update ||
      this.thismodel.crud == CrudType.updatestrvalue ||
      this.thismodel.crud == CrudType.updatememovalue
      ? true
      : false;
  }

  isFile(): boolean {
    if (this.thismodel.valuetyp == undefined) return false;
    return (
      this.thismodel.valuetyp.toLowerCase() == "picture" ||
      this.thismodel.valuetyp.toLowerCase() == "file" ||
      this.thismodel.valuetyp.toLowerCase() == "movie" ||
      this.thismodel.valuetyp.toLowerCase() == "audio"
    );
  }

  gotoEmailLink(): void {
    var p = <ISrchValue>{
      searchtype: SearchType.unbekannt,
      strvalue: this.thismodel.strvalue,
      typenames: ["Adresse"],
      top: 1,
    };

    var user = store.getters.user;
    dataService.InitbyToken(user.token);
    dataService
      .post<ISrchValue, IStrValue[]>("Service/GetValues", p)
      .then((g) => {
        if (g.length > 0) {
          router.push("/dyn/" + g[0].base_id + "/update/" + g[0].typname);
        } else {
          globalInfoService.sendMessage(<IInfo>{
            msg: "Adresse nicht gefunden für " + this.thismodel.strvalue,
            type: InfoType.warning,
            error: null,
          });
        }
      })
      .catch((ex) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Adresse nach email holen",
          type: InfoType.debug,
          error: ex,
        });
      });
  }

  gotoInnerLink(): void {
    router.push(
      "/dyn/" +
        this.thismodel.child_base_id +
        "/update/" +
        this.thismodel.typname
    );
  }

  public getLocation(model: IStrDetailValue): void {
    if (!model) return;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          var lat = position.coords.latitude;
          var long = position.coords.longitude;
          model.crud = CrudType.updatestrvalue;
          model.strvalue = lat + ", " + long;
          model.memovalue = lat + ", " + long;
          this.model = model;
          globalUpdateDetailService.sendMessage(model);
        },
        (e) => {
          globalInfoService.sendMessage(<IInfo>{
            msg: "Geolocation Fehler ",
            error: `${e.toString()}`,
            type: InfoType.debug,
          });
        }
      );
    } else {
      globalInfoService.sendMessage(<IInfo>{
        msg: "Geolocation geht nicht ",
        error: "wird nicht unterstützt",
        type: InfoType.error,
      });
    }
  }

  isLocation(): boolean {
    return (
      this.thismodel.fieldname.toLowerCase() == "ort" &&
      !this.thismodel.strvalue
    );
  }
  isInnerLink(): boolean {
    return (
      this.thismodel.valuetyp != undefined &&
      this.thismodel.valuetyp.toLowerCase() === "innerlink"
    );
  }

  isValidEmail(): boolean {
    if (
      this.thismodel.strvalue &&
      this.thismodel.strvalue.length > 0 &&
      this.thismodel.strvalue.toLowerCase() ==
        store.getters.user.email.toLowerCase()
    )
      return false;
    return (
      (this.thismodel.inputtype != undefined &&
        this.thismodel.inputtype.toLowerCase() == "email") ||
      (this.thismodel.valuetyp != undefined &&
        this.thismodel.valuetyp.toLowerCase() == "email") ||
      this.thismodel.fieldname.toLowerCase() == "email" ||
      (this.thismodel.strvalue.length > 0 &&
        store.getters.user.email &&
        (this.thismodel.fieldname.toLowerCase() == "to" ||
          this.thismodel.fieldname.toLowerCase() == "from"))
    );
  }

  isTel(): boolean {
    return (
      this.thismodel.valuetyp != undefined &&
      this.thismodel.inputtype.toLowerCase() == "tel"
    );
  }

  mounted(): void {
    this.token = store.getters.user.token;
    if (this.IsSecureText()) {
      var user = store.getters.user;
      dataService
        .GetEncryptCode(user.base_id)
        .then((g) => {
          this.encryptcode = g;
        })
        .catch((ex) => {
          this.thismodel.visibility = "hidden";
          globalInfoService.sendMessage(<IInfo>{
            msg: "Unmöglich sichere Daten zu holen",
            type: InfoType.debug,
            error: ex,
          });
        });
    }
  }

  @Watch("showAction")
  onPropertyChanged(value: any) {
    if (!value) return;
    this.showthisAction = value;
    dataService.InitbyToken(store.getters.user.token);
    var urlfieldnames = "Service/GetUrlLink/" + this.thismodel.id;
    dataService
      .get<IUrllink>(urlfieldnames)
      .then((f) => {
        this.urllink = f;
      })
      .catch((e) => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Link aufbauen geht nicht...",
          error: e,
        });
      });
  }

  created(): void {
    globalInfoActionService.getMessage().subscribe((g) => {
      if (g.id == this.thismodel.id) {
        this.info = g;
      }
    });
  }

  computed() {}
}
